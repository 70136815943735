html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  vertical-align: baseline;
  background: no-repeat;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: 500;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@font-face {
  font-family: Commodore64AngledRegular;
  src: url("commodore_angled_v1.2-webfont.f169b3df.eot");
  src: url("commodore_angled_v1.2-webfont.f169b3df.eot#iefix") format("embedded-opentype"), url("commodore_angled_v1.2-webfont.54fa8dcb.woff") format("woff"), url("commodore_angled_v1.2-webfont.f982d371.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body, html {
  width: 100%;
  height: 100%;
}

body {
  color: #fff;
  background: #a0a0ff;
  font-family: Commodore64AngledRegular;
  font-size: 12px;
  line-height: 16px;
}

input[type="text"] {
  color: #fff;
  white-space: pre;
  float: left;
  display: hidden;
  color: #0000;
  background: none;
  border: none;
}

div#main {
  color: #a0a0ff;
  width: 660px;
  background: #4040e0;
  margin: 100px auto;
  padding: 20px;
}

#cmd p {
  height: 20px;
  width: 100%;
  margin: 10px 0;
  display: block;
}

span#blinky {
  color: #a0a0ff;
  background: #a0a0ff;
}

.hidden {
  display: none;
}

/*# sourceMappingURL=index.f497b719.css.map */
