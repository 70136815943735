html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-weight: 500;
    background: transparent;
    background-position: 0 0;
    background-repeat: no-repeat;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}

del {
    text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* ---- MOJE ---- */
a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@font-face {
    font-family: 'Commodore64AngledRegular';
    src: url('commodore_angled_v1.2-webfont.eot');
    src: url('commodore_angled_v1.2-webfont.eot?#iefix') format('embedded-opentype'),
    url('commodore_angled_v1.2-webfont.woff') format('woff'),
    url('commodore_angled_v1.2-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

body, html {
    width: 100%;
    height: 100%;
}

body {
    background: #a0a0ff;
    color: white;
    font-family: 'Commodore64AngledRegular';
    font-size: 12px;
    line-height: 16px;
}

input[type="text"] {
    background: #4040e0;
    color: white;
    border: none;
    white-space: pre;
    float: left;
    display: hidden;
    background: transparent;
    color: transparent;
}

div#main {
    background: #4040e0;
    color: #a0a0ff;
    width: 660px;
    margin: 100px auto 100px auto;
    padding: 20px;
}

#cmd p {
    display: block;
    height: 20px;
    width: 100%;
    margin: 10px 0 10px 0;
}

span#blinky {
    color: #a0a0ff;
    background: #a0a0ff;
}
.hidden {
    display: none;
}
